import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';

import ImageMobile from '../images/image-mobile.png';
import ImageDesktop from '../images/image-web.png';

import { FakeLocationInput } from './FakeLocationInput';

export const LocationBanner = () => {
  const { t } = useTranslation('home');
  const createSessionState = useSession('createSessionState');
  const destroySessionState = useSession('destroySessionState');
  const authRole = useSession('authRole');
  const { push } = useRouter();

  const isUserLoggedIn =
    createSessionState.session &&
    destroySessionState.status !== 'loading' &&
    authRole === AUTH_ROLE.AUTHENTICATED;

  return (
    <div
      className={cn(
        'w-full',
        'bg-primary-600',
        'text-white',
        'font-light',
        'relative',
        'overflow-hidden'
      )}
    >
      <div
        className={cn(
          'grid',
          'grid-flow-row',
          'auto-rows-fr',
          'min-[430px]:grid-flow-col',
          'min-[430px]:max-lg:grid-cols-[2fr,1fr]',
          'lg:auto-cols-fr',
          'items-end',
          'mx-auto',
          'max-w-7xl',
          'w-full',
          '2xl:relative',
          'pl-4',
          'xl:pl-0'
        )}
      >
        <div
          className={cn(
            'pt-8',
            'sm:pt-18',
            'text-base',
            'self-start',
            'sm:pb-12',
            'lxl:relative',
            'z-10'
          )}
        >
          <div
            className={cn(
              'mb-32',
              isUserLoggedIn ? ['pb-4', 'sm:pb-0', 'sm:mb-18'] : 'sm:mb-12'
            )}
          >
            <h1 className={cn('text-4xl', 'mb-5', 'leading-10', 'font-normal')}>
              <Trans
                ns="home"
                i18nKey="Welcome to <Italic>delio</Italic>"
                components={{
                  Italic: (
                    <span
                      className={cn('font-medium', 'font-serif', 'italic')}
                    />
                  ),
                }}
              />
            </h1>
            <p className={cn('mb-6')}>
              {t('Enter your address to check product availability')}
            </p>

            <FakeLocationInput />
          </div>

          {!isUserLoggedIn && (
            <div
              className={cn(
                'flex',
                'items-end',
                'w-full',
                'justify-between',
                'gap-0',
                'sm:block'
              )}
            >
              <span
                className={cn('shrink', 'max-w-32', 'sm:max-w-none', 'pb-6')}
              >
                <Trans
                  ns="home"
                  i18nKey="Already have an account? <Link>Sign In</Link>"
                  components={{
                    Link: (
                      // eslint-disable-next-line jsx-a11y/control-has-associated-label
                      <button
                        type="button"
                        className={cn(
                          'inline-block',
                          'font-normal',
                          'underline'
                        )}
                        onClick={() => push('sign-in')}
                      />
                    ),
                  }}
                />
              </span>
            </div>
          )}
        </div>

        <div
          className={cn(
            'absolute',
            'right-0',
            'bottom-0',
            'h-[135px]',
            'min-[340px]:h-[160px]',
            'min-[370px]:h-[170px]',
            'max-w-[685px]',
            'md:h-[200px]',
            'lg:h-[270px]',
            'xl:h-full',
            'xl:top-0'
          )}
        >
          <div
            className={cn(
              'location-banner-gradient',
              'absolute',
              'w-full',
              'h-full',
              '-bottom-10',
              '-right-10',
              'lg:bg-[length:70%_100%]',
              'lg:-right-60',
              'xl:-right-40',
              'xl:bottom-0',
              'xl:bg-auto'
            )}
          />
          <Image
            src={ImageMobile}
            alt="Delio"
            className="relative h-full w-auto lg:hidden"
            width={275}
            height={200}
            priority
          />
          <Image
            src={ImageDesktop}
            alt="Delio"
            className="relative hidden h-full w-auto lg:block"
            width={685}
            height={351}
            priority
          />
        </div>
      </div>
    </div>
  );
};
