import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { useLocationModal } from '@/modules/location/hooks/useLocationModal';

import GeolocationIcon from '../../location/images/geolocation-icon.svg';

export const FakeLocationInput = () => {
  const { t } = useTranslation('home');
  const { openLocationModal } = useLocationModal();

  return (
    <div
      className={cn(
        'flex',
        'gap-3',
        'sm:gap-4',
        'xl:gap-2',
        'mb-14',
        'md:mb-12',
        'pr-4',
        'flex-col',
        'min-[430px]:flex-row'
      )}
    >
      <button
        data-testid="fake-location-input-button"
        type="button"
        className={cn(
          'bg-white',
          'h-12',
          'py-3',
          'px-4',
          'rounded-lg',
          'border',
          'border-gray-400',
          'text-gray-400',
          'flex',
          'justify-between',
          'items-center',
          'w-full',
          'max-w-md',
          'min-w-max'
        )}
        onClick={openLocationModal}
      >
        <p>{t('np. Wiejska 1, Warszawa')}</p>
        <GeolocationIcon className={cn('w-6', 'h-6', 'text-gray-750')} />
      </button>

      <button
        type="button"
        className={cn(
          'shrink-0',
          'min-w-12',
          'h-12',
          'px-8',
          'text-base',
          'font-medium',
          'rounded-lg',
          'border-transparent',
          'text-white',
          'bg-primary-400',
          'focus-visible:ring-primary-200'
        )}
        onClick={openLocationModal}
      >
        {t('Enter address')}
      </button>
    </div>
  );
};
